$text-base: theme("colors.gray.800")
$text-primary: #024BD5
$text-secondary: #707070
$text-tertiary: #FFBD44
$text-contrast: #fff

$text-product-primary: #023f5e

$background-button: #024BD5

$background-primary: #F7F7F7
$background-secondary: #EAF1FF
$background-tertiary: #E8E8E8
$background-quaternary: #09134C
$background-penitentiary: #002F87
$background-tint: #DEE9FF
$background-gradient: linear-gradient(to bottom, #024BD5, #001C50)

$success: theme('colors.green.600')
$danger: theme('colors.red.600')