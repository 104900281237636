@import "styles/variable"
@import "styles/general"

.blog-page-blog
  @apply pb-16
  color: $text-product-primary
  @screen lg
    @apply flex justify-center pt-8

  & > .inner-container
    @screen lg
      max-width: 680px

    & .title-container, & .author-container, & .main-content, & .tag-container
      @extend .common-x-padding
      @screen lg
        @apply px-0

    & > .header
      @apply grid
      grid-template-areas: "image" "title" "author" "tag-list"
      @screen lg
        grid-template-areas: "title" "author" "tag-list" "image"

      & > img
        @apply w-full
        grid-area: image
        @screen lg
          @apply mt-8

      & > .title-container
        @apply mt-4
        grid-area: title
        & > .title
          @apply text-3xl font-medium
          color: $text-primary
          @screen lg
            @apply text-4xl

      & > .author-container
        @apply flex justify-between mt-6
        grid-area: author

        & > .author-info
          @apply flex items-center

          & > img
            @apply rounded-full w-10 h-10
            @apply mr-2
            @screen lg
              @apply mr-4 w-12 h-12

          & > .basic-info
            & > .name
              @apply font-medium -mb-1
              @screen lg
                @apply text-2xl
            & > .time-info
              @apply text-sm
              @apply flex
              @screen lg
                @apply text-lg
              & > .dot
                @apply mx-2

        & > .social-media-container
          @apply flex items-end
          & > .social-media
            @apply text-2xl mr-3
            filter: grayscale(100%)
            @screen lg
              @apply text-3xl

      & > .tag-list
        @extend .common-x-padding
        @apply flex
        @apply mt-4
        grid-area: tag-list
        @screen lg
          @apply px-0
        & > *
          @apply border rounded-full
          @apply px-4
          border-color: $text-primary
          color: $text-primary


    & > .main-content
      @apply mt-6
      & > *
        @apply mb-4
      & ul
        @apply list-disc
      & ol
        @apply list-decimal

    & > .tag-container
      @apply mt-4
      @screen lg
        @apply mt-8

      & > h4
        @apply text-xl font-semibold
      & > ul
        @apply flex mt-2
        & > li
          @apply capitalize border rounded-xl
          @apply px-4 py-1 mr-2 mb-1
          border-color: $background-quaternary