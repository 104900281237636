.download-buttons
  @apply flex flex-col gap-4
  @apply w-full
  @screen md
    @apply flex-row

  > a
    &:not(:last-child)
      @apply mb-2

    > .download-button-img
      @apply w-full