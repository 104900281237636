@import "styles/variable"

.carousel
  > .content-container
    @apply overflow-auto
    @apply flex
    > .item
      @apply w-full

  > .navigation-container
    @apply flex justify-center
    @apply gap-2

    > .navigation-dot
      @apply filter grayscale
      @apply duration-200
      color: $text-primary

      &--active
        @apply filter grayscale-0

  &--contrast
    > .navigation-container
      > .navigation-dot:not(.navigation-dot--active)
        color: $text-contrast