@import "styles/variable"
@import "styles/general"

.feature-detail
  @apply flex justify-center w-full
  @apply relative

  > .bottom-illustration-component
    @apply absolute bottom-0 left-0
    @apply min-w-full
    @apply z-0

  > .feature-detail-inner
    @extend .common-maxwidth
    @apply flex items-center
    @apply px-0 pt-28 pb-16
    @apply static
    z-index: 1
    @screen lg
      @apply py-28

    > .detail-content-container
      @apply flex-1
      > .detail-content
        @apply flex flex-col items-center
        max-width: 33.125rem
        @screen lg
          @apply items-start

        > .content-title
          @apply flex items-center
          @apply mb-2
          color: $text-primary
          > .content-title-text
            @apply ml-1 font-semibold
          > svg
            @apply w-5

        > .content-description
          @apply text-3xl font-semibold text-center
          @screen md
            @apply text-4xl
          @screen lg
            @apply text-left

        > .learn-more
          @apply font-semibold text-xl
          @apply mt-8
          color: $text-primary
          &:hover, &:focus
            @apply outline-none
            @apply underline

        > .content-button
          @extend .common-button
          @apply flex justify-center mt-6

        > .content-items-container
          @apply shadow-md rounded-md
          @apply border-2
          @apply bg-white
          @apply mt-6
          max-width: 31rem

          > .content-item
            @apply flex flex-row
            @apply border-b-2
            @apply p-8

            > .item-img-container
              > .item-img
                width: 2.5rem
                height: 2.5rem
            > .item-content
              @apply ml-10
              > .item-content-title
                @apply text-xl font-semibold
                @apply mb-4
                color: $text-primary
              > .item-content-description
                @apply leading-5

          > .content-item:last-child
            @apply border-b-0

    > .detail-image-container
      @apply flex-1 mt-16
      > img
        @apply mx-auto max-w-none
        height: 43.75rem