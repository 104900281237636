@import "styles/variable"
@import "styles/general"

.pricing-container
  @extend .common-spacing
  background-color: $background-penitentiary
  .pricing
    @extend .common-spacing
    @apply w-full
    @apply text-center
    h1
      @extend .common-h1
      @apply text-4xl
    h2
      @extend .common-h2
    h4
      @extend .common-h4
    p
      @extend .common-small-text

    .plan-container
      @extend .common-spacing
      background-color: $background-quaternary
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 98%)
      @apply w-full
      @screen lg
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%)
      .title
        @apply text-white
      .plan-wrapper
        @extend .common-maxwidth
        @apply px-8 py-12
        @screen lg
          @apply px-12 py-20
      .plan
        @extend .common-spacing
        @screen lg
          @apply grid grid-cols-2 gap-5
          @apply my-10
        @screen 2xl
          @apply grid grid-cols-4 gap-5
    .compare-container
      @extend .common-spacing
      @apply w-full
      h1
        color: $text-tertiary
      button
        @extend .common-button

      .compare
        @extend .common-maxwidth
        @apply px-8 py-12
        @screen lg
          @apply px-12 py-20
        .title-mobile
          @apply flex flex-row
          @apply justify-between
          @apply w-full
          @apply px-5
          .plans
            @apply flex flex-row
            @apply justify-around
            @apply w-7/12
            p
              writing-mode: vertical-lr
              text-orientation: mixed
              color: $text-tertiary
              @apply m-0
          @screen lg
            @apply hidden
        .compare-table-container
          @extend .common-spacing
          @extend .common-small-text
          @apply rounded-lg
          @apply p-5 mb-5
          background-color: $background-quaternary
          @screen lg
            @apply my-5
          @screen md
            @apply w-full

          .compare-table
            @apply w-full
            tr:not(:first-child)
              height: 60px
              @apply border-b-2
              border-color: rgba($text-primary, 0.5)
            tr:nth-child(2)
              @apply hidden
              @screen lg
                @apply contents
            tr:last-child
              @apply border-none
            th
              color: $text-tertiary
              @apply pr-1
            td
              @apply text-white
              @apply w-24
            th:first-child, td:first-child
              @apply text-left
              @apply w-5/12
            tr:first-child
              @apply hidden
              @screen lg
                @apply table-row

            .pricing-container > * > *
                @apply flex justify-center items-center
                & > :first-child:not(:only-child)
                  @apply mr-2