@import "styles/variable"
@import "styles/general"

.pricing-card-wrapper
  @apply h-full w-full
  @apply my-10
  @screen lg
    @apply my-0
  .pricing-card
    height: 656.78px
    max-width: 357.06px
    @apply relative
    @apply w-full
    @apply rounded-lg
    @apply justify-center
    @apply bg-white
    @screen lg
      max-width: 404.5px
    .light-background
      @apply rounded-lg
      background-color: $text-primary
      opacity: 5%
      @apply h-40 w-full
      @apply absolute
      @apply z-0
    .popular
      @apply flex flex-row
      @apply justify-center items-center
      @apply w-full
      @apply p-2
      @apply rounded-md
      @apply text-white
      background-color: $background-button
      @apply absolute
      @apply z-0
      svg
        color: #FFBD44
        @apply mx-2
    .des
      @extend .common-spacing
      @apply px-2 pt-2
      @apply z-10 relative
      height: 400px
      button, a
        @extend .common-button
        @apply my-4

      .price
        @apply flex items-center
        @apply my-4
        color: $text-primary
        & > :first-child:not(:only-child)
          @apply mr-2

      .illustration
        height: 160px
        @apply mt-4
    .content
      @apply px-2 pb-2
      @apply border-t-2
      @apply w-full
      p
        @apply leading-10
