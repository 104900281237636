@import "styles/variable"
@import "styles/general"

.header-text
  @apply text-lg font-semibold
  @apply cursor-pointer
  @apply duration-200
  @apply relative

  &::before
    @apply absolute bottom-0 left-0 right-full
    @apply bg-transparent
    @apply duration-300
    @apply h-1
    content: ""

  &--active, &:hover
    &::before
      @apply right-0
      background-color: $text-primary
  &--blur
    @apply opacity-40


.header-container
  @apply flex items-center justify-center
  @apply bg-gray-50
  @apply top-0 z-40
  @apply sticky
  @apply h-20
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16)
  & > .inner-container
    @extend .common-maxwidth
    @apply flex flex-row
    @apply h-full

    .header
      @apply flex flex-row
      @apply justify-start items-center
      @apply w-3/5

      > .content
        @apply flex items-center
        @apply h-full

        > *:not(.header-submenu)
          @apply flex items-center
          @apply h-full
          @apply ml-8

      > .logo
        @apply w-40
        svg
          @apply w-full


.header-item-with-submenu
  @apply h-full
  > .header-text
    @apply flex items-center
    @apply h-full

    > .icon
      @apply duration-500
      @apply text-base
      @apply ml-4

    &.header-text--active > .icon
      @apply transform rotate-180

.header-submenu
  @apply absolute left-0 right-0 top-full
  @apply flex justify-center
  @apply overflow-hidden
  @apply duration-500
  @apply bg-gray-50
  @apply shadow-lg
  @apply border-t

  &--active
    @apply h-20
  &--inactive
    @apply h-0

  > .inner-container
    @apply absolute left-0 top-0
    @apply h-full
    @apply h-20
    @apply flex

    > *
      @apply flex items-center
      @apply duration-200
      @apply h-full
      @apply mr-12


      > .icon
        @apply duration-200
        @apply mr-2

      &:hover > .icon
        color: $text-primary

    > .header-text--active
      color: $text-primary
