@import "styles/variable"
@import "styles/general"

.footer-info
  @apply flex justify-center
  & > .footer-info-inner
    @extend .common-maxwidth
    @apply py-16
    & > .logo-and-sections
      @apply flex flex-col items-center
      @screen lg
        @apply flex-row items-start
      & > .logo-container
        & > .logo
          @apply mx-auto mb-10
          width: 9.375rem
          @screen md
            width: 14rem
          @screen lg
            @apply mr-28

      & > .sections-container
        @apply flex flex-col
        @apply gap-4
        @screen md
          @apply grid grid-cols-2
          @apply gap-8
        @screen lg
          @apply flex flex-row

        & > .section
          @apply text-center
          @screen md
            @apply text-left
          &:not(:last-child)
            @screen lg
              @apply mr-20
          & > .section-title
            @apply font-semibold
            @apply mb-2
          > .section-item
            @apply mb-1

        & > .icons-container
          & .icon
            @apply text-2xl
            @apply mx-2
            color: $text-primary


.footer-bottom
  @apply flex justify-center
  background: $background-penitentiary
  & > .footer-bottom-inner
    @extend .common-maxwidth
    @apply flex justify-between
    @apply py-5 text-white

    @screen md
      @apply justify-start
      > *:not(:last-child)
        @apply mr-6