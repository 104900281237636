@import "styles/variable"
@import "styles/general"

.feature-showcase
  background: $background-gradient
  color: $text-contrast

  > .outer-container
    @extend .common-maxwidth
    @apply flex flex-col items-center
    @apply px-0 pt-24
    @apply w-full
    @screen lg
      @apply flex-row justify-between
      @apply py-24

    > .showcase-content
      @apply flex flex-col items-center
      @screen lg
        @apply items-start
        max-width: 34.75rem
      > .content-header
        @apply text-4xl font-semibold text-center
        max-width: 20rem
        @screen md
          @apply text-6xl
          max-width: 33rem
        @screen lg
          @apply text-left

      > .content-body
        @apply text-xl text-center
        @apply my-6
        max-width: 18rem
        @screen md
        max-width: 27rem
        @screen lg
          @apply text-left

      .download-buttons
        @apply mb-20
        @screen lg
          @apply mt-8

    > .img-container
      @apply flex
      > img
        width: 17rem
        height: 33rem
        @screen lg
          width: 14rem
          height: 28rem
        @screen xl
          width: 18rem
          height: 36rem

    > .carousel
      > .content-container
        @apply px-4 pb-6
        > img
          @apply mx-4
          height: 32rem
          width: 16rem

  > .highlight-section
    @apply flex flex-col items-center
    @apply w-full
    @apply mt-24
    > .highlight-title
      @apply text-3xl font-semibold text-center
      @apply mb-16
      color: $text-tertiary
      max-width: 20rem
      @screen md
        @apply text-4xl
        max-width: 25rem
      @screen lg
        @apply max-w-full

    > .highlight-items
      @extend .common-maxwidth
      @apply flex flex-col items-center justify-center
      @apply pb-16
      @screen md
        max-width: 25rem
      @screen lg
        @apply flex-row items-start
        @apply max-w-full
        @apply pb-20

      > .item
        @apply flex flex-col items-center
        @apply rounded-xl shadow-md
        @apply py-9 px-2
        @apply mb-6
        background-color: $background-primary
        max-width: 20rem
        @screen md
          @apply px-8
        @screen lg
          @apply mb-0 mx-5
          max-width: 20rem
        @screen xl
          max-width: 20rem
        > .item-icon-and-title
          @apply text-2xl font-semibold
          @apply flex items-center
          color: $text-primary
          @screen lg
            @apply text-2xl

          > .title
            @apply ml-2

        > .item-image
          @apply w-28 h-28
          @apply mb-10
          > svg
            @apply w-full h-full
        > .item-description
          @apply text-center text-xl font-medium
          @apply mt-5
          color: $text-base