@import "styles/variable"
@import "styles/general"

.sidebar-container
  @extend .common-spacing
  @apply z-40 top-0 sticky
  @apply shadow-md
  @apply bg-white
  @apply w-full

  .sidebar-wrapper
    @extend .common-maxwidth, .common-x-padding
    @apply justify-center items-center
    @apply grid grid-cols-3
    @apply w-full
    @apply py-2
    .icon
      @apply flex justify-start items-center
    .logo
      @apply flex justify-start items-center
      img
        @apply w-full
    .button
      @apply flex justify-end items-center
      a
        @extend .common-small-button
        @apply text-xs
        @apply px-2
        @screen md
          @apply text-base
        @screen lg
          @apply px-1

.mobile-sidebar-slider
  @extend .common-x-padding
  @apply flex justify-center
  @apply pointer-events-none
  @apply fixed inset-0
  @apply overflow-auto
  @apply duration-300
  @apply bg-white
  @apply z-50
  @apply py-2
  clip-path: circle(0% at 0% 0%)
  @screen md
    @apply py-4
  &-active
    @apply pointer-events-auto
    clip-path: circle(100%)

  & > .inner-container
    @extend .common-maxwidth
    @apply px-0

    & > *
      @apply w-full
    & > .header
      @apply flex justify-between
      & > .logo
        @apply w-40
      & > .close-cta
        @apply text-lg

    & > .content-container
      @apply w-full h-full
      @apply relative
      @apply mt-8

      & > .content
        @apply mb-16

        .link
          @apply flex items-center
          @apply duration-200
          @apply border-b
          @apply text-xl
          @apply py-4
          color: $text-primary
          filter: grayscale(1)

          & > :first-child
            @apply mr-4

          &-active
            filter: grayscale(0)

        > .item-with-sub-menu
          > .opener
            @apply flex items-center justify-between
            @apply filter grayscale
            @apply duration-200
            @apply border-b
            @apply w-full
            @apply py-4
            color: $text-primary

            &--active
              @apply grayscale-0

            > .content
              @apply flex items-center
              @apply text-xl

              > :first-child
                @apply mr-4



          > ul > li
            @apply border-l-2 border-gray-300
            @apply mt-4 ml-2
            @apply text-lg
            @apply flex

            > a
              @apply filter grayscale
              @apply pl-4 py-1
              @apply w-full
              color: $text-primary
              &.link-active
                @apply grayscale-0
              > *:first-child
                @apply mr-2

      & > *
        @apply mb-2

