@import "./globalVars"
@import "../variable"

html
  color: $text-base
  font-family: $font-primary
  scroll-behavior: smooth

.app-container
  @apply relative
  & > .app-header
    @apply flex flex-col justify-center items-center
    @apply text-2xl text-green-300
    @apply w-screen h-screen
    @apply bg-blue-800

  .app
    @apply overflow-hidden
  .sidebar-container
    @screen lg
      @apply hidden
  .header-container
    @apply hidden
    @screen lg
      @apply flex
