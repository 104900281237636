@import "styles/variable"
@import "styles/general"

.feature-social-feed-section
  @apply flex justify-center w-full
  background-color: $background-tint

  > .inner-container
    @extend .common-maxwidth
    @apply flex flex-col
    @apply pt-20 px-0
    @screen lg
      @apply flex-row

    > .info-container
      @apply px-8

      > .info
        @apply flex flex-col justify-center items-center
        @apply rounded-lg shadow-md
        @apply self-center
        @apply text-center
        @apply py-16 px-4
        @apply mx-auto
        background-color: $background-primary
        max-width: 31rem
        @screen md
          @apply px-14
        @screen lg
          @apply text-left
          @apply mt-16
          width: 33.75rem

        > .header
          @apply flex flex-col items-center
          @apply w-full
          @apply mb-4
          @screen lg
            @apply items-start

          > .title
            @apply flex items-center
            @apply font-semibold
            color: $text-primary

            > :first-child
              @apply mr-2

          > .sub-title
            @apply font-semibold text-3xl
            max-width: 10rem
            @screen md
              @apply max-w-full
              @apply text-4xl

        > .description
          @apply text-2xl

    > .video-player
      @apply flex flex-col items-end
      @apply overflow-visible
      @apply w-full
      @screen md
        @apply items-center
      @screen lg
        @apply items-end
        @apply pr-16

      > .video-container
        width: 41rem !important
