@import "styles/general"
@import "styles/variable"

.data-deletion-instruction-page
  @apply flex flex-col items-center h-full

  .data-deletion-instruction-page-inner
    @apply mt-8
    @extend .common-maxwidth

    .heading-image
      @apply h-72

    p
      @apply mt-8

    ol
      @apply list-decimal mt-4 pl-4