@import "styles/variable"

.common-h1
  @apply text-2xl
  @screen lg
    @apply text-6xl

.common-h2
  @apply text-2xl
  @screen lg
    @apply text-4xl

.common-h3
  @apply text-xl
  @screen lg
    @apply text-3xl

.common-h4
  @apply text-lg
  @screen lg
    @apply text-lg

.common-text
  @apply text-xl
  @screen lg
    @apply text-xl

.common-small-text
  @apply text-base

.common-button
  @apply text-xl text-white
  @apply p-2 w-56
  @apply rounded-3xl
  @apply outline-none
  @apply border-none
  @apply duration-200
  background-color: $background-button
  &:hover, &:focus
    filter: brightness(1.2)
  @screen lg
    @apply text-xl

.common-small-button
  @apply text-sm text-white
  @apply rounded-3xl
  @apply p-1
  @apply outline-none
  background-color: $background-button
  @apply duration-200
  &:hover, &:focus
    filter: brightness(1.2)
  @screen lg
    @apply text-xl
    @apply w-40
    @apply p-2

.common-spacing
  @apply flex flex-col
  @apply justify-center items-center

.common-maxwidth
  @apply w-full px-8
  @screen sm
    @apply px-0
    max-width: 540px
  @screen md
    max-width: 720px
  @screen lg
    max-width: 960px
  @screen xl
    max-width: 1140px
  @screen 2xl
    max-width: 1200px

.common-x-padding
  @apply px-6
  @screen lg
    @apply px-16
