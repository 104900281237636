@import "styles/variable"
@import "styles/general"

.feature-page
  > *:not(.feature-detail)
    @extend .common-spacing

  > .journal-section-detail
    background: $background-gradient

    > .feature-detail-inner
      > .detail-content-container

        > .detail-content
          > .content-title
            color: $text-tertiary
          > .content-description, > .learn-more
            color: $text-contrast


  > .feature-bottom
    @apply flex justify-center w-full
    background: $background-gradient
    > .feature-bottom-inner
      @extend .common-maxwidth
      @apply flex flex-col items-center pt-28
      > .section-title
        @apply text-6xl font-semibold text-center
        color: $text-contrast
      > .section-description
        @apply text-2xl text-center
        color: $text-contrast
        @apply mt-7 mb-12
      > .section-actions
        max-width: 25rem
      > .section-bottom-imgs
        @apply w-full relative
        @apply mt-16
        @apply h-60
        @screen lg
          @apply mt-20

        > img
          @apply absolute mx-auto
          width: 21.875rem
          left: 0
          right: 0
          &.img-center
            @apply mx-auto
          &.img-left
            top: 3.75rem
            left: -10%
            right: 10%
          &.img-right
            top: 3.75rem
            left: 10%
            right: -10%
