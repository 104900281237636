@import "styles/general"
@import "styles/variable"

.blog-page-main
  @apply relative
  @apply w-full
  @apply flex flex-col items-center

  & > *
    @apply relative

  &::before
    @apply top-0 left-0 absolute
    @apply w-full
    @apply z-0
    content: ""
    background-color: $text-primary
    height: 20rem

  .category-modal-open-button
    @apply font-bold text-xl
    @apply flex items-start
    @screen lg
      @apply hidden

  & > .blog-header
    @apply text-4xl text-center
    @apply py-16
    color: $text-contrast

  // & > .search-small
  //   @apply w-full my-6
  //   @screen lg
  //     @apply hidden

  & > .blog-body
    @extend .common-maxwidth
    @apply w-full
    @apply pb-8

    & > .card-section
      @apply relative

      & > .pagination-container
        @apply mt-8

      & > .loading-overlay
        @apply absolute top-0 left-0 right-0 bottom-0
        @apply flex justify-center items-center
        @apply bg-black bg-opacity-50

        & p
          @apply transform -translate-y-8
          @apply text-3xl font-semibold
          color: $text-contrast
        & .spinner-container
          @apply absolute transform -translate-x-1/2
          top: 50%
          left: 50%
          & > * > *
            background-color: $text-primary

      & > .inner-container
        @extend .common-x-padding
        @screen md
          @apply grid grid-cols-2 gap-8
        @screen xl
          @apply grid-cols-3
        @screen 2xl
          grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr))

        & > *
          @apply mb-4
          @screen lg
            @apply mb-0
        & > .no-result-text
          @apply text-lg bg-black bg-opacity-50
          color: $text-contrast