@import "styles/variable"

.pagination-container
  @apply flex justify-center w-full

  .active-color
    color: $text-contrast
    background-color: $text-product-primary

  & > *
    &:hover:not(.disabled) > a
      filter: brightness(1.25)
      @extend .active-color

    & > a
      @apply flex justify-center items-center
      @apply mr-4 w-8 h-8 rounded-full
      @apply duration-200 shadow-lg
      color: $text-secondary

      &:focus:active
        @apply shadow-none
      &:focus
        @apply outline-none

  & > .active > a
    @extend .active-color

  & > .disabled > a
    @apply cursor-not-allowed
    filter: grayscale(100%)