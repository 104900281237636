@import "styles/variable"

.blog-preview-card
  @apply flex flex-col justify-items-center items-start
  @apply shadow-lg overflow-hidden relative
  height: 20rem
  @screen lg
    height: 25rem

  .hover-effect
    &:hover
      @apply underline
      filter: brightness(1.1)

  & > .preview-image
    @extend .hover-effect
    @apply duration-200 overflow-hidden
    @apply w-full h-56 cursor-pointer
    @screen lg
      @apply h-64
    & > img
      @apply h-full w-full

  & > .card-wrapper
    @apply p-4 pb-8 w-full flex flex-col
    color: $text-product-primary
    background-color: $text-contrast

    & > .meta-container
      @apply flex flex-row items-center
      h5
        @apply opacity-75
        color: $text-secondary

    & > .title
      @extend .hover-effect
      @apply text-xl font-medium
      @apply line-clamp-2
      @screen lg
        @apply text-2xl cursor-pointer duration-200

    & > .author
      @extend .hover-effect
      @apply opacity-80
      @apply text-left
      @screen lg
        @apply cursor-pointer